<script setup lang="ts">
import { XIcon } from 'lucide-vue-next'
import { cn } from '~/lib/utils'
interface Props {
  show: boolean
  class?: string
  size?:
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | '3.5xl'
    | '4xl'
    | '5xl'
    | '6xl'
    | '7xl'
    | 'full'
  showCloseButton?: boolean
  padding?: string
  showHeading?: boolean
  canClose?: boolean
  title?: string
  headerPadding?: string
}
interface Emits {
  (event: 'close-modal', value: null): void
}

const emit = defineEmits<Emits>()
const props = withDefaults(defineProps<Props>(), {
  size: 'md',
  class: '',
  padding: 'px-6 py-4',
  showCloseButton: true,
  showHeading: true,
  canClose: true,
  title: '',
  headerPadding: 'py-4',
})

// I did it like this because that's how it works,
// see: https://tailwindcss.com/docs/content-configuration#dynamic-class-names
const sizeVariants: Record<(typeof props)['size'], string> = {
  sm: 'max-w-sm',
  md: 'max-w-md',
  lg: 'max-w-lg',
  xl: 'max-w-xl',
  '2xl': 'max-w-2xl',
  '3xl': 'max-w-3xl',
  '3.5xl': 'max-w-[800px]',
  '4xl': 'max-w-4xl',
  '5xl': 'max-w-5xl',
  '6xl': 'max-w-6xl',
  '7xl': 'max-w-7xl',
  full: 'max-w-full',
}

const modalContentRef = ref<HTMLDivElement>()

function closeModal() {
  if (props.canClose) {
    return emit('close-modal', null)
  } else {
    const modalContent = modalContentRef.value
    if (!modalContent) return
    modalContent.classList.add('shake')
    modalContent.onanimationend = () => {
      modalContent.classList.remove('shake')
    }
  }
}

watch(
  () => props.show,
  (newVal) => {
    if (newVal) {
      document.body.classList.add('modal-open')
    } else {
      document.body.classList.remove('modal-open')
    }
  },
  { immediate: true },
)
</script>

<template>
  <Teleport to="body">
    <Transition name="fade-scale">
      <div
        v-if="show"
        :class="
          cn(
            'max-w fixed bottom-0 left-0 right-0 top-0 z-50 flex items-center justify-center bg-black/75 p-4',
            props.class,
          )
        "
        @click.self="closeModal"
      >
        <div
          ref="modalContentRef"
          :class="[sizeVariants[size]]"
          class="flex max-h-[90vh] w-full flex-shrink-0 flex-col rounded-[0.5rem] bg-white"
        >
          <div :class="padding" class="custom-scrollbar overflow-auto">
            <div
              v-if="showHeading"
              class="flex items-start justify-between gap-4 border-b"
              :class="[headerPadding]"
            >
              <p class="flex-grow text-xl font-bold text-[#1A2D5B]">
                <slot name="title">{{ title }}</slot>
              </p>
              <button
                v-if="showCloseButton"
                class="ml-auto rounded-full text-white hover:bg-accent hover:text-accent-foreground"
                @click="closeModal"
              >
                <XIcon class="h-7 w-7 text-[#343330]" />
                <span class="sr-only">Close</span>
              </button>
            </div>
            <slot />
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<style>
body.modal-open {
  max-height: 100vh;
  overflow: hidden;

  /* pointer-events: auto !important; */
}

.fade-scale-leave-from,
.fade-scale-enter-to {
  scale: 1;
  opacity: 1;
}

.fade-scale-enter-from,
.fade-scale-leave-to {
  scale: 0.99;
  opacity: 0;
}

.fade-scale-enter-active,
.fade-scale-leave-active {
  transition: all 200ms;
}

.shake {
  animation: shake 0.25s forwards;
}

@keyframes shake {
  0% {
    scale: 1;
  }

  25% {
    scale: 0.98;
  }

  50% {
    scale: 1;
  }

  75% {
    scale: 0.98;
  }

  100% {
    scale: 1;
  }
}
</style>
